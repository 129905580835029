import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../AppActions';
import Constants from '../../Constants';
import BaseSection from '../../Components/BaseSection';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import TextSection from '../../Components/TextSection';
import {useOutlet} from 'reconnect.js';

import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';

import {gTagEvent} from '../../Hooks/useGoogleAnalytics';

import {Button, Input} from 'antd';
import {Radio, Select, Space} from 'antd';

import nft from '../../Utils/Nft';

//import {compress, decompress} from 'lzutf8';
import LZString from 'lz-string';

import CryptoJS from 'crypto-js';

import * as Scroll from 'react-scroll';

// Function to compress and encrypt a string
const compressAndEncrypt = (text, password) => {
  // Compress the text
  //const compressed = compress(text, {outputEncoding: 'Base64'});
  const compressed = LZString.compressToBase64(text);

  // Encrypt the compressed text using the password
  const encrypted = CryptoJS.AES.encrypt(compressed, password).toString();

  // Return the encrypted text
  return encrypted;
};

// Function to decrypt and decompress a string
const decryptAndDecompress = (encryptedText, password) => {
  // Decrypt the encrypted text using the password
  const bytes = CryptoJS.AES.decrypt(encryptedText, password);
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);

  // Decompress the decrypted text
  //const decompressed = decompress(decrypted, {inputEncoding: 'Base64'});
  const decompressed = LZString.decompressFromBase64(decrypted);

  // Return the decompressed text
  return decompressed;
};

// Usage example
/*
const _password = '6a[9/M[u6ubcByRERhh.}CV.fA^eGH';
const originalText =
  'Lorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing elit.' +
  'Lorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing elit.' +
  'Lorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing elit.';
const encryptedText = compressAndEncrypt(originalText, _password);
const decryptedText = decryptAndDecompress(encryptedText, _password);

console.log('!!!! 1', originalText, originalText.length); // "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
console.log('!!! 2', encryptedText, encryptedText.length); // "U2FsdGVkX19zvG8XlB6YIY7Vn0n05vvd8VJW6q3q/yM5Q5c5x6XoUamMDG60dH5P"
console.log('!!! 3', decryptedText); // "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
*/

function Landing(props) {
  useOutlet('update-counter');
  const [test, setTest] = React.useState({});
  const [dimension] = useOutlet('dimension');

  const isMobile = dimension.rwd === 'mobile';

  return (
    <Wrapper
      style={{backgroundImage: `url("/images/bg${isMobile ? '3' : ''}.png")`}}>
      <div style={{width: '100%', maxWidth: 1024}}>
        <Navbar />
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'transparent',
        }}>
        <InfoSection doTest={setTest} />
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'transparent',
        }}>
        <div
          style={{
            width: '100%',
            maxWidth: 1024,
            backgroundColor: 'rgba(255,255,255,0.3)',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
          }}>
          <div style={{padding: 10, flex: 1, minWidth: 300}}>
            <EncodeSection />
          </div>
          <div style={{padding: 10, flex: 1, minWidth: 300}}>
            <DecodeSection test={test} />
          </div>
        </div>
      </div>

      <div style={{width: '100%', maxWidth: 1024}}>
        <Footer />
      </div>
    </Wrapper>
  );
}

const InfoSection = ({style = {}, doTest = () => 0}) => {
  const [more, setMore] = React.useState(false);
  return (
    <div
      style={{
        width: '100%',
        maxWidth: 1024,
        backgroundColor: 'rgba(255,255,255,0.9)',
        display: 'flex',
        flexDirection: 'column',

        padding: '10px 20px',

        ...style,
      }}>
      <div
        style={{
          padding: '25px 20px',
          backgroundColor: '#9CA777',
          borderRadius: 10,
          alignSelf: 'center',
        }}>
        <h1 style={{margin: 0}}>Encrypt & Decrypt Text on BlockChain</h1>
      </div>
      <br />
      <h2 style={{color: '#5F264A'}}>
        Encrypt text data with AES256 and store it directly on the{' '}
        <div style={{display: 'inline-block', textDecoration: 'underline'}}>
          Optimism chain
        </div>{' '}
        for permanent preservation, making it easily accessible and trustworthy.
      </h2>
      <h3
        style={{
          alignSelf: 'center',
          color: '#D25380',
          marginTop: 30,
          marginBottom: 30,
          fontSize: 24,
        }}>
        [ Please Switch Your MetaMask Network to Optimism First ! ]
      </h3>
      {more && (
        <>
          <div style={{fontSize: 18, fontWeight: 'bold'}}>Applications</div>
          {[
            [
              'Evidence preservation',
              'By storing important information on the blockchain, it can be easily preserved as evidence for future use.',
            ],
            [
              'Information transmission',
              'Information can be securely transmitted to the intended recipients without the need for additional systems.',
            ],
          ].map((item, idx) => (
            <div key={idx}>
              {idx + 1}.{' '}
              <div style={{display: 'inline-block', fontWeight: 'bold'}}>
                {item[0]}
              </div>
              : {item[1]}
            </div>
          ))}
          <br />
          <div style={{fontSize: 18, fontWeight: 'bold'}}>Features</div>
          {[
            'Permanently stored on the blockchain, ensuring it cannot be lost or tampered with.',
            'This webpage directly connects to the blockchain, eliminating the risk of third-party interference.',
            'The smart contract serves as the final version.',
            'High security. Data is encrypted using AES256 and can be password-protected.',
            'No limit on plain text content, and users can also encrypt the content themselves.',
          ].map((item, idx) => (
            <div key={idx}>
              {idx + 1}. {item}
            </div>
          ))}
          <br />
          <div style={{fontSize: 18, fontWeight: 'bold'}}>Fee / Max Size</div>
          <div style={{color: '#D25380', fontWeight: 'bold'}}>
            0.0025 ETH per 128 bytes (Max 0.025 ETH)
          </div>
          <br />
          <div style={{fontSize: 18, fontWeight: 'bold'}}>Scenarios</div>
          Alice wants to send a message to Bob:
          {[
            'Agree on a password through a secure channel (e.g. Telegram).',
            'Alice enters the message and password into the system to transmit the information to the blockchain and obtain a data ID.',
            'Alice informs Bob of the data ID.',
            'Bob retrieves the original message using the data ID and password.',
          ].map((item, idx) => (
            <div key={idx}>
              {idx + 1}. {item}
            </div>
          ))}
          <br />
        </>
      )}

      <Button
        onClick={() => setMore(!more)}
        style={{width: '50%', maxWidth: 200, alignSelf: 'center'}}>
        More Info&nbsp;
        {!more ? '▽' : '▵'}
      </Button>
      <br />
      <div style={{fontSize: 18, fontWeight: 'bold', color: '#FF6D60'}}>
        Let's Try !
      </div>
      <div>
        Please enter the following information (Data ID:{' '}
        <div
          style={{color: 'red', display: 'inline-block', fontWeight: 'bold'}}>
          1
        </div>
        &nbsp; / Password:{' '}
        <div
          style={{color: 'red', display: 'inline-block', fontWeight: 'bold'}}>
          HideAllHideAll
        </div>
        &nbsp;) into "Read Data from Chain" area to retrieve the data .{' '}
        <div
          style={{
            color: '#569DAA',
            fontWeight: 'bold',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => {
            gTagEvent('Click', {name: 'TRY NOW'});

            Scroll.scroller.scrollTo('_DecodeSection', {
              duration: 1500,

              smooth: true,

              offset: 50, // Scrolls to element + 50 pixels down the page
            });

            doTest({id: 1, password: 'HideAllHideAll'});
          }}>
          [ TRY NOW ]
        </div>
      </div>
      <br />
      <br />
      <img
        src="/images/og2.png"
        style={{
          width: '80%',
          maxWidth: 700,
          minWidth: 350,
          borderRadius: 10,
          alignSelf: 'center',
        }}
      />
    </div>
  );
};

function partialAddress(address = '', h = 8, t = 8) {
  const head = address.substring(0, h);
  const end = address.substring(address.length - t, address.length);
  return head + '...' + end;
}

const EncodeSection = () => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [text, setText] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const [uploadIdx, setUploadIdx] = React.useState(null);

  const [gasMul, setGasMul] = React.useState('Low');

  function isDataValid(s) {
    return s !== null && s !== undefined && s !== '';
  }

  const encryptedResult =
    isDataValid(text) && isDataValid(password) && password.length >= 6
      ? compressAndEncrypt(text, password)
      : null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#569DAA',
        opacity: 0.95,
        padding: 15,
        borderRadius: 10,
      }}>
      <h1
        style={{
          backgroundColor: '#263A29',
          color: '#F2E3DB',
          padding: '5px 15px',
          borderRadius: 5,
        }}>
        Save Data on Chain ⇧
      </h1>

      <h2 style={{color: '#394867', fontSize: 16, alignSelf: 'flex-start'}}>
        1. Click "Connect to Wallet (OP chain)" button to link your MetaMask
      </h2>
      <h2 style={{color: '#394867', fontSize: 16, alignSelf: 'flex-start'}}>
        2. Fill the text data you want to upload to "Data to be uploaded" area.
      </h2>
      <h2 style={{color: '#394867', fontSize: 16, alignSelf: 'flex-start'}}>
        3. Fill your password or click "Gen PWD" to encrypt text data.
      </h2>
      <h2 style={{color: '#394867', fontSize: 16, alignSelf: 'flex-start'}}>
        4. Click "Upload to OP chain" to upload encrypted data.
      </h2>
      <h2 style={{color: '#394867', fontSize: 16, alignSelf: 'flex-start'}}>
        5. You will get data ID after uploading success. Then you could use data
        ID and password to read text data in the future.
      </h2>

      <Button
        onClick={() => {
          if (nft.account) {
            nft.disconnect(forceUpdate);
            setText(null);
            setPassword(null);
            setPasswordVisible(false);
            setUploadIdx(null);
            gTagEvent('Click', {name: 'DISCONNECT (push section)'});
          } else {
            nft.connect(forceUpdate);
            gTagEvent('Click', {name: 'CONNECT (push section)'});
          }
        }}>
        {nft.account
          ? 'Disconnect (' + partialAddress(nft.account) + ')'
          : 'Connect to Wallet (OP chain)'}
      </Button>

      {nft.account && (
        <>
          <h3 style={{marginTop: 50, alignSelf: 'flex-start'}}>
            Data to be uploaded {text && `(data length：${text.length})`}
          </h3>
          <Input.TextArea
            value={text}
            rows={4}
            style={{marginTop: -10, width: '100%'}}
            placeholder="Please input your data"
            onChange={(e) => setText(e.target.value)}
          />
          <h3 style={{marginTop: 20, alignSelf: 'flex-start'}}>Password</h3>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}>
            <Input.Password
              placeholder="Please Input password"
              style={{width: '50%'}}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              visibilityToggle={{
                visible: passwordVisible,
                onVisibleChange: setPasswordVisible,
              }}
            />
            <Button
              disabled={!password}
              style={{width: '15%'}}
              onClick={async () => {
                if (password) {
                  await window.navigator.clipboard.writeText(password);
                  window.alert('password copyied !');
                }
              }}>
              Copy
            </Button>
            <Button
              style={{width: '30%'}}
              onClick={() => {
                gTagEvent('Click', {name: 'Gen Pwd (push section)'});
                setPassword(generatePassword(16));
              }}>
              Gen Pwd
            </Button>
          </div>
          {password && password.length < 6 && (
            <div style={{color: 'red', alignSelf: 'flex-start'}}>
              password must exceed 6 letters !
            </div>
          )}
          <div
            style={{
              width: '100%',
              backgroundColor: '#EEEEEE',
              marginTop: 20,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 10,
              padding: 20,
            }}>
            {encryptedResult ? (
              <div style={{fontWeight: 'bold'}}>
                Fee: {nft.getFee(encryptedResult, 0.0025)} ETH
              </div>
            ) : (
              ''
            )}

            {encryptedResult && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 50,
                  alignItems: 'center',
                }}>
                GAS Level &nbsp;
                <Radio.Group
                  value={gasMul}
                  onChange={(e) => setGasMul(e.target.value)}>
                  <Radio.Button value="Low">Low</Radio.Button>
                  <Radio.Button value="Medium">Medium</Radio.Button>
                  <Radio.Button value="High">High</Radio.Button>
                </Radio.Group>
              </div>
            )}
            <Button
              disabled={
                !(
                  isDataValid(text) &&
                  isDataValid(password) &&
                  password &&
                  password.length >= 6
                )
              }
              style={{marginTop: 20}}
              onClick={async () => {
                gTagEvent('Click', {name: 'Upload (push section)'});
                nft.monitorMint((id, data) => {
                  if (decryptAndDecompress(data, password) === text) {
                    setUploadIdx(id);
                    gTagEvent('Behavior', {
                      name: 'Upload Success (push section)',
                      dataId: id,
                    });
                  }
                });

                try {
                  let _gasMul = 1.1;
                  if (gasMul === 'Medium') {
                    _gasMul = 1.2;
                  }
                  if (gasMul === 'High') {
                    _gasMul = 1.5;
                  }
                  setUploadIdx(null);
                  gTagEvent('Behavior', {
                    name: 'Gas Level (push section)',
                    level: _gasMul,
                  });
                  nft.payableMint(encryptedResult, _gasMul);
                } catch (err) {
                  gTagEvent('Behavior', {
                    name: 'Upload fail (push section)',
                    reason: JSON.stringify(err),
                  });
                  window.alert(
                    'Upload fail ! Maybe you could\n1. Check whether the network is op mainnet\n2. Select higher gas level',
                  );
                }
              }}>
              Upload to OP chain
            </Button>
          </div>

          {uploadIdx !== null && (
            <div
              style={{
                marginTop: 50,
                backgroundColor: '#FCFFB2',
                padding: 10,
                borderRadius: 10,
              }}>
              Upload Success ! <br />
              You could use{' '}
              <p style={{display: 'inline-block', color: 'red'}}>
                **password**
              </p>{' '}
              and <p style={{display: 'inline-block', color: 'red'}}>Data ID</p>{' '}
              to decode data
              <div>
                <div
                  style={{
                    display: 'inline-block',
                    fontSize: 24,
                    fontWeight: 'bold',
                  }}>
                  Data ID :
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    fontSize: 24,
                    fontWeight: 'bold',
                    color: '#146C94',
                  }}>
                  {uploadIdx}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const DecodeSection = ({test = {}}) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [dataID, setDataID] = React.useState(null);
  const [text, setText] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  React.useEffect(() => {
    async function f() {
      if (test.id != null && test.password != null) {
        setDataID(test.id);
        setPassword(test.password);
        await delay(200);
        try {
          //const ret = await nft.getData(dataID);

          delay(500);
          //setText(decryptAndDecompress(ret, password));
        } catch (err) {
          console.log('fail ...', err);
          setText(null);
          /*
          window.alert(
            'Error ... \n1. Please check data ID and password \n2. We need MetaMask for connecting to blockchain',
          );
          */
        }
      }
    }
    f();
  }, [test]);

  function isDataValid(s) {
    return s !== null && s !== undefined && s !== '';
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#393646',
        opacity: 0.9,
        padding: 15,
        borderRadius: 10,
        color: 'white',
      }}>
      <Scroll.Element name="_DecodeSection">
        <h1
          style={{
            backgroundColor: '#4F4557',
            color: '#F4EEE0',
            padding: '5px 15px',
            borderRadius: 5,
          }}>
          Read Data from Chain ⇩
        </h1>
      </Scroll.Element>

      <h2 style={{color: 'white', fontSize: 16}}>
        Pull Data from OP chain by data ID and password.
      </h2>

      <>
        <h3 style={{marginTop: 20, alignSelf: 'flex-start', color: 'white'}}>
          Data ID
        </h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}>
          <Input
            type="number"
            placeholder="Please Input Data ID"
            style={{width: '50%'}}
            value={dataID}
            onChange={(e) => setDataID(e.target.value)}
          />
        </div>
        <h3 style={{marginTop: 20, alignSelf: 'flex-start', color: 'white'}}>
          Password
        </h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}>
          <Input.Password
            placeholder="Please Input password"
            style={{width: '50%'}}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
          />
        </div>
        <Button
          disabled={!(isDataValid(dataID) && isDataValid(password))}
          style={{marginTop: 50}}
          onClick={async () => {
            setText(null);

            if (/*!(await nft.isOptimismNetwork())*/ false) {
              window.alert('Please switch to optimism mainnet first');
            } else {
              gTagEvent('Click', {
                name: 'Download (pull section)',
              });
              try {
                const ret = await nft.getData(dataID);

                setText(decryptAndDecompress(ret, password));
                gTagEvent('Behavior', {
                  name: 'Decode Success (pull section)',
                });
              } catch (err) {
                console.log('fail ...', err);
                setText(null);
                window.alert(
                  'Error ... \n1. Please check data ID and password \n2. We need MetaMask for connecting to blockchain\n3. Please switch to Optimism mainnet',
                );
                gTagEvent('Behavior', {
                  name: 'Decode fail (pull section)',
                  reason: JSON.stringify(err),
                });
              }
            }
          }}>
          Pull Data and Decrypt
        </Button>

        {text && (
          <>
            <h3 style={{marginTop: 50, alignSelf: 'flex-start'}}>
              Result for ID {dataID} {text && `data length：${text.length}`}
            </h3>
            <Input.TextArea
              value={text}
              rows={4}
              style={{marginTop: -10, width: '100%'}}
              placeholder="Please input your data"
              onChange={(e) => setText(e.target.value)}
            />
          </>
        )}
      </>
    </div>
  );
};

const Wrapper = styled.div`
  background-image: url('/images/bg.png');
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

function generatePassword(length, rules) {
  if (!length || length == undefined) {
    length = 8;
  }

  if (!rules || rules == undefined) {
    rules = [
      {chars: 'abcdefghijklmnopqrstuvwxyz', min: 3}, // As least 3 lowercase letters
      {chars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', min: 2}, // At least 2 uppercase letters
      {chars: '0123456789', min: 2}, // At least 2 digits
      {chars: '!@#$&*?|%+-_./:;=()[]{}', min: 1}, // At least 1 special char
    ];
  }

  var allChars = '',
    allMin = 0;
  rules.forEach(function (rule) {
    allChars += rule.chars;
    allMin += rule.min;
  });
  if (length < allMin) {
    length = allMin;
  }
  rules.push({chars: allChars, min: length - allMin});

  var pswd = '';
  rules.forEach(function (rule) {
    if (rule.min > 0) {
      pswd += shuffleString(rule.chars, rule.min);
    }
  });

  return shuffleString(pswd);
}
function shuffleString(str, maxlength) {
  var shuffledString = str
    .split('')
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join('');
  if (maxlength > 0) {
    shuffledString = shuffledString.substr(0, maxlength);
  }
  return shuffledString;
}

var pswd = generatePassword(15, [
  {chars: 'abcdefghijklmnopqrstuvwxyz', min: 4}, // As least 4 lowercase letters
  {chars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', min: 1}, // At least 1 uppercase letters
  {chars: '0123456789', min: 3}, // At least 3 digits
  {chars: '!@#$&*?|%+-_./:;=()[]{}', min: 2}, // At least 2 special chars
]);
export default Landing;
